<template>
  <q-card-section class="row form-inputs-content justify-between">
    <div
      v-for="(structure, i) in [1, 2]"
      :key="i"
      class="full-width row no-wrap"
    >
      <q-skeleton :type="'QSlider'" height="50px" class="full-width q-mt-md" />
    </div>
  </q-card-section>
</template>

<script>
export default {
  name: "ContactDetailsSkeleton",
};
</script>
